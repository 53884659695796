@function HexToRGB($hex) {
  @return red($hex), green($hex), blue($hex);
}

:root[data-theme="light"] {
  --theme-icon-color: #000000;
  --background-color: #fafafa;
  --header-background: #ffffff;
  --main-background: #ffffff;
  //Theme toggle
  --toggle-background: #414141;
  --tiggle-switch: #ffffff;
  //Map
  --map-filter: saturate(0);
  //Input
  --input-background: #fbfbfb;
  --search-icon-color: #2b2b2b;
  //Card
  --card-background: rgba(204, 204, 204, 0.88);
  --card-text-color: #2b2b2b;
  --card-hour-color:#adadad;
  --main-card-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.5);

  --border-hover-day: 3px solid ;

  //select
  --input-text-color: #2b2b2b;
  --select-background-color: #2b2b2b;
  --select-background-color-hover: #f0f0f0;
  --select-text-color-hover: #fafafa;
  --toggle-rate: 1;
  // :root[data-tg="true"] {
  //   --main-background: var(--tg-theme-bg-color);
  // }
  // JSON value color
  --punctuation-text-color: #67879c;
  --value-string-color: #438d2c;
  --value-bool-color: #a06cdd;
  --value-number-color: #ea8048;
  // --tg-gradient: linear-gradient(to bottom,  #ffffff00 0%, #ffffffff 100%);
}

:root[data-theme="dark"] {

  --theme-icon-color: #ffffff;

  --background-color: #414141;
  --header-background: #414141;
  --main-background: #414141;
  //Theme toggle
  --toggle-background: #ffffff;
  --tiggle-switch: #414141;
  //Map
  // --map-filter: brightness(0.6) invert(1) contrast(100) hue-rotate(200deg) saturate(0) brightness(1);
  // --map-filter: brightness(0.6) invert(1) contrast(5) hue-rotate(1150deg) saturate(50) brightness(0.5); //TOR
  // --map-filter: brightness(0.6) invert(1) contrast(5) hue-rotate(200deg) saturate(0) brightness(0.5);
  --map-filter: brightness(0.6) invert(1) contrast(3) hue-rotate(200deg) saturate(0) brightness(0.7); //INTERNET
  --map-background: #303030;
  //Input
  --input-background: #303030;
  --search-icon-color: #fafafa;
  //Card
  --card-background: rgba(117, 117, 117, 0.88);
  --card-text-color: #eaeaea;
  --card-hour-color:#575757;
  
  --main-card-shadow: 0px 0px 10px 5px rgba(255, 255, 255, 0.7);
  
  --border-hover-day: 3px solid rgb(7, 7, 7);
  //select
  --input-text-color: #fafafa;
  --select-background-color: #fafafa;
  --select-background-color-hover: #303030;
  --select-text-color-hover: #2b2b2b;
  --toggle-rate: 1;
    // JSON value color
  --punctuation-text-color: #a5dafd;
  --value-string-color: #c1eeb2;
  --value-bool-color: #c898ff;
  --value-number-color: #FFA16F;
  // --tg-gradient: linear-gradient(to bottom, #17212b00 0%,#ffffffff 100%);
  // --tg-gradient: linear-gradient(to bottom, #17212b00 0%,#17212bFF 100%);
}


// $toggle-rate: 1;

:root[data-tg="true"] {
  // $background-color: var(--tg-theme-bg-color);
  // $color: 
  // --color: var(--tg-theme-bg-color);
  --main-background: var(--tg-theme-bg-color);
  --input-background: var(--tg-theme-bg-color);
  --input-text-color: var(--tg-theme-text-color);
  --input-border: var(--tg-theme-button-text-color);
  --search-icon-color: var(--tg-theme-text-color);
  // --bla: rgba(var(--tg-theme-bg-color), 0)
  // --blo: rgba(var(--tg-theme-bg-color), 1)
  // --tg-gradient: linear-gradient(to bottom, color-mix(in srgb, var(--tg-theme-bg-color), transparent 100%) 0%, color-mix(in srgb, var(--tg-theme-bg-color), transparent 0%) 100%);


  // $--theme-primary: --main-background;
  // --opacity-color: #{HexToRGB(var(--tg-theme-bg-color))};
  // --opacity-color-to: #{HexToRGB(#E20071)};

  // bg_color var(--tg-theme-bg-color) +
  // secondary_bg_color	var(--tg-theme-secondary-bg-color)
  // text_color	var(--tg-theme-text-color)
  // hint_color	var(--tg-theme-hint-color)
  // link_color	var(--tg-theme-link-color)
  // button_color	var(--tg-theme-button-color)
  // button_text_color	var(--tg-theme-button-text-color)
}

$extra-small: 0px;
$small: 600px;
$medium: 900px;
$large: 1200px;
$extra-large: 1536px;
