@import '../../../../sass/variables';

.inputContainer {
  position: relative;
  margin: 7px;
  border-radius: 4px;
  border: 2px solid transparent;
  box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
  // padding: 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  // textarea:focus, input:focus{
  //   outline: none;
  // }
  .mainForm {
    width: 100%;

    .maininput {
      outline: none;
      padding: 7px;
      font-size: 16px;
      // border-radius: 4px;
      // font-weight: bold;
      width: 100%;
      background: var(--input-background, #ffffff);
      border: 0px solid transparent;
      // box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
      color: var(--input-text-color);

      :focus {
        border: 2px solid #000;
        outline: none;
        border-radius: 4px;
      }
    }
  }

  .Flag {
    width: 50px;
    align-self: center;
    // height: 40px;
    border-radius: 5px;
  }

  .clear {
    all: unset;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 42px;

    // color: var(--search-icon-color);
    :hover {
      cursor: pointer;
    }

    svg {
      // color: var(--search-icon-color);
      // color: red;
      fill: var(--search-icon-color);
      padding: 3px;
      height: 100%;
      width: 100%;
    }
  }

  .select_container {
    color: var(--input-text-color);
    position: absolute;
    top: auto;
    left: 0px;
    margin-top: 5px;

    .select {
      padding: 7px;
      background-color: var(--select-background-color, #ffffff);
      width: 600px;

      &:hover {
        cursor: pointer;
        background-color: var(--select-background-color-hover, #ffffff);
        color: var(--select-text-color-hover, #ffffff);
      }
    }
  }
}