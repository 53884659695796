@import '../..//../sass/variables';

.map {
  width: 100%;
  height: calc(100vh - 60px);
  
  @media (max-width: $small) {
    // width: 100%;
    height: calc(50vh - 60px);
  }
}

