@import '../../../../sass/variables';




.flagContainer {
  cursor: pointer;
  position: relative;
  
  .Flag {
    width: 24px;
    height: 100%;
    align-self: center;
    box-shadow: 0 0 0 0.5px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
    // height: 40px;
    // border-radius: 5px;
  }
  // .maininput {
  //   padding: 7px;
  //   border-radius: 4px;
  //   font-size: 16px;
  //   width: 600px;
  //   background: var(--input-background, #ffffff);
  //   border: 2px solid transparent;
  //   box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
  //   color: var(--input-text-color);

  //   :focus{
  //     border: 2px solid #000;
  //     border-radius: 4px;
  //   }
  // }

  .select_flag {
    color: var(--input-text-color);
    position: absolute;
    top: auto;
    left: -5px;
    margin-top: 7px;
    z-index: 100;

    .select {
      z-index: 100;
      padding: 5px;
      background-color: var(--select-background-color, #ffffff);
      // background-color: var(--select-background-color, #ffffff);
      // width: 100%;
      width: 34px;
      // border-radius: 5px;
      display: flex;
      flex-direction: row;

      &:hover {
        cursor: pointer;
        background-color:  var(--select-background-color-hover, #ffffff);
        // color: var(--select-text-color-hover, #ffffff);
      }
    }
  }
}