@import '../../sass/variables';

.main {
  width: 100%;
  height: 100%;
  background-color: var(--main-background);

  z-index: 1;
  // height: calc(100vh - 60px);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;


  .headerLoader {
    width: 100%;
    height: 60px;
    background-color: var(--header-background);
  }
}