
@import '../../sass/variables';

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // background-color: var(--background-color);
}
