@import '../../../sass/variables';

.header {
  width: 100%;
  height: 60px;
  padding: 10px;
  background-color: var(--header-background);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1005;

  -webkit-box-shadow: 0px 100px 100px -49px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 100px 100px -49px rgba(0, 0, 0, 0.61);
  box-shadow: 0px 100px 100px -49px rgba(0, 0, 0, 0.61);

  .menu {
    width: 60vw;
    height: 100%;
    margin: auto;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: $medium) {
      width: 100%;
    }

    .leftMenu {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      // justify-items: center;s
      gap: 10px;
      .tloginButton  {
        padding-top: 5px;
        // margin: auto 0;
        display: flex;
        align-self: center;
        align-content: center;
        align-items: center;
      }
    }
  }
}