@import '../../../../sass/variables';

.logoContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;

  .logo {
    height: 40px;
    width: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  .logoName {
    font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    color: var(--card-text-color);
    font-size: 20px;
    // @media (max-width: $small) {
    //   display: none;
    // }
  }
}

.iconDisplay {
  display: none;
}