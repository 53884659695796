@import '../../../../sass/variables';

.botLink {
  all: unset;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 33px;

  :hover {
    cursor: pointer;
  }

  svg {
    // fill: var(--theme-icon-color);
    padding: 3px;
    height: 100%;
    width: 100%;
  }
}