@import '../../../../sass/variables';

.punctuation {
  color: var(--punctuation-text-color);
}
.scroller {
  div {
    transform: translate3d(0,0,0);
  }
}
// .infoWrapper {
  

  .infoContainer {
    font-family: ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-size: 16px;
    position: relative;
    // left: 5px;
    // margin: 7px;
    height: 105%;
    // width: 100%;
    max-height: 100%;
    // border-radius: 4px;
    // border: 2px solid transparent;
    // box-shadow: 0 0 0 1px #dddddd, 0 2px 4px 0 rgb(0 0 0 / 7%), 0 1px 1.5px 0 rgb(0 0 0 / 5%);
    // padding: 0px 5px 0px 5px;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // overflow: hidden;
    // overflow-y: auto; // Прокрутка внутри дива, если содержимое больше него
    // overflow-x: hidden;
  
    // // white-space: initial;
  
    // Скрыть скроллбар
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    // overflow-y: scroll;
    // overflow: inherit;
    // overflow: -moz-scrollbars-none;
    overflow-wrap: break-word;
    word-wrap: break-word; 
    
    // // Скрыть скроллбар
    // ::-webkit-scrollbar {
    //   display: none; /* for Chrome, Safari, and Opera */
    //   width: 0 !important;
    // }
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; 
    ::-webkit-scrollbar {
      display: none;
      width: 0;
    }

    //-----------------------------------------
    .stringContainer {

      .floatLeft {
        float: left;
        display: flex;
        margin-right: 0.5rem;
  
        .Icon {
          height: 20px;
          margin-right: 0.5rem;
        }
        .stringKey {
          // text-transform: lowercase;
          color: var(--input-text-color);
        }
  
        .stringKeyError {
          // text-transform: lowercase;
          color: #dc8484;
        }
  
        .stringValueContainer{
          display: flex;
          flex-direction: row;
  
          .stringValue {
            // перенос текста
            // overflow-wrap: break-word;
            // word-wrap: break-word; 
            // white-space: initial;
            // line-height: 50px;
            color: var(--value-string-color);
          }
          .stringBoolValue {
            color: var(--value-bool-color);
          }
          .stringNumberValue {
            color: var(--value-number-color);
          }
        }
    
    
        .punctuation {
          color: var(--punctuation-text-color);
        }
    
      }
      .stringValue {
        // перенос текста
        overflow-wrap: break-word;
        word-wrap: break-word; 
        // white-space: initial;
        color: var(--value-string-color);
      }
      .stringBoolValue {
        color: var(--value-bool-color);
      }
      .stringNumberValue {
        color: var(--value-number-color);
      }
    }
    
    //-----------------------------------------
    // .stringContainer {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: flex-start;
    //   gap: 7px;
    //   overflow-wrap: break-word;
    //   word-wrap: break-word; 
  
    //   .stringKeyContainer{
    //     display: flex;
    //     flex-direction: row;
        
    //     .stringKey {
    //       text-transform: lowercase;
    //       color: var(--input-text-color);
    //     }
    //     .stringKeyError {
    //       // text-transform: lowercase;
    //       color: #dc8484;
    //     }
    //   }
    //   .stringValueContainer{
    //     display: flex;
    //     flex-direction: row;
        
    //     .stringValue {
    //       // перенос текста
    //       overflow-wrap: break-word;
    //       word-wrap: break-word; 
    //       // white-space: initial;
    //       color: var(--value-string-color);
    //     }
    //     .stringBoolValue {
    //       color: var(--value-bool-color);
    //     }
    //     .stringNumberValue {
    //       color: var(--value-number-color);
    //     }
    //   }
  
  
    //   .punctuation {
    //     color: var(--punctuation-text-color);
    //   }
    // }
    // $color: var(--main-background);
  
    // $red: red(#{var(--main-background)});
    // $green: green(#{var(--main-background)});
    // $blue: blue(#{var(--main-background)});
  
    .gradient {
      min-height: 25px;
      width: 1000%; // Костыль!!!!!!!!!!!!!!!!!!!!!!!!!!надо как то решить!!!!!!!!!!!!!!!!!
      position: sticky;
      bottom: 0px;
      // z-index: 5;
      // background-color: red;
      position:-webkit-sticky; 
      position: sticky; 
                      // linear-gradient(90deg,var(--color) 50%,var(--new-color) 0)
      // background: -moz-linear-gradient(top,  #17212b00 0%, #17212bFF 100%); /* FF3.6+ */
      // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#17212b00), color-stop(100%,#17212bFF)); /* Chrome,Safari4+ */
      // background: -webkit-linear-gradient(top,  #17212b00 0%,#17212bFF 100%); /* Chrome10+,Safari5.1+ */
      // background: -o-linear-gradient(top,  #17212b00 0%,#17212bFF 100%); /* Opera 11.10+ */
      // background: -ms-linear-gradient(top,  #17212b00 0%,#17212bFF 100%); /* IE10+ */
      // background: linear-gradient(to bottom,  #17212b00 0%,#17212bFF 100%); /* W3C */
  
  
      // background: -moz-linear-gradient(top,  color-mix(in srgb, var(--main-background), transparent 100%) 0%, color-mix(in srgb, var(--main-background), transparent 0%) 100%); /* FF3.6+ */
      // background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,color-mix(in srgb, var(--main-background), transparent 100%)), color-stop(100%,color-mix(in srgb, var(--main-background), transparent 0%))); /* Chrome,Safari4+ */
      // background: -webkit-linear-gradient(top,  color-mix(in srgb, var(--main-background), transparent 100%) 0%,color-mix(in srgb, var(--main-background), transparent 0%) 100%); /* Chrome10+,Safari5.1+ */
      // background: -o-linear-gradient(top,  color-mix(in srgb, var(--main-background), transparent 100%) 0%,color-mix(in srgb, var(--main-background), transparent 0%) 100%); /* Opera 11.10+ */
      // background: -ms-linear-gradient(top,  color-mix(in srgb, var(--main-background), transparent 100%) 0%,color-mix(in srgb, var(--main-background), transparent 0%) 100%); /* IE10+ */
  
      // transparentize(#ff0000, 0.5)
  
      background: linear-gradient(to bottom, transparent, var(--main-background));
      // background: linear-gradient(to bottom, color-mix(in srgb, var(--main-background), transparent 100%) 0%, color-mix(in srgb, var(--main-background), transparent 0%) 100%);
      // background: var(--tg-gradient); //linear-gradient(to bottom,  srgb(var($--theme-primary), 0) 0%,#17212bFF 100%); /* W3C */
  
      // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0089fff1', endColorstr='#000000',GradientType=0 ); /* IE6-9 */
      // top:0;
    }
  
  }
// }
// }