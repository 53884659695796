@import '../../../sass/variables';

.main {
  width: 100%;
  height: 100px;
  background-color: var(--main-background);

  z-index: 10;
  height: calc(100vh - 60px);
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}