@import '../../../sass/variables';

.leaflet-container {
  width: 100%;
  height: 100%;
}

.leaflet-tile {
  filter: var(--map-filter,none);;
}

.leaflet-container {
    background: var(--map-background,none);
}