@import '../../../sass/variables';

// .main {
//   width: 100%;
//   height: 100px;
//   background-color: var(--main-background);

//   z-index: 10;
//   height: calc(100vh - 60px);
//   min-height: 100%;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-start;
//   position: relative;
// }

.main {
  
  z-index: 1000;
  position: absolute;
  top: 100vh;
  // left: 75%;
  left: 100vw;
  min-height: calc(100vh - 60px - 10vh);
  max-height: calc(100vh - 60px - 10vh);
  // mix-height: 85%;
  // max-height: 85%;
  width: 30%;
  min-width: 300px;
  transform: translate(-105%, -105%);
  padding: 7px;
  font-size: 16px;
  border-radius: 10px;
  background: var(--card-background, rgba(255,255,255,0.88));
  color: var(--card-text-color, #000);

  -webkit-box-shadow: var(--main-card-shadow);
  -moz-box-shadow: var(--main-card-shadow);
  box-shadow: var(--main-card-shadow);
  // overflow-y: hidden; // Прокрутка внутри дива, если содержимое больше него
  // overflow-x: hidden;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 7px;
  
  @media (max-width: $small) {
    // position: block;
    // height: 100%;
    // width: 100%;
    


    position: inherit;
    width: 100%;
    top: 0%;
    left: 0%;
    transform: none;
    border-radius: 0%;
    // min-width: 355px;
    height: 50vh;
    min-height: 50vh;
    max-height: 50vh;

    
  }
  // @media (max-width: 1420px) {
  //   transform: translate(-60%, -50%);
  // }
  // @media (max-width: 1140px) {
  //   position: inherit;
  //   width: 100%;
  //   top: 0%;
  //   left: 0%;
  //   transform: none;
  //   border-radius: 0%;
  //   min-width: 355px;
  // }

  // :focus{
  //   border-radius: 4px;
  // }

  // .currentDay {
  //   display: flex;
  //   justify-content: space-between;
  //   .currentDayWeather {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //   }
  //   .currentCity {
  //     font-size: 17px;
  //   }
  //   .currentDegree {
  //     font-size: 80px;
  //     align-self: center;
  //     text-align: center;
  //   }
  //   .currentFeelsLike {
  //     font-size: 12px;
  //   }
  //   .currentDescription {
  //     font-size: 15px large;
  //     &:first-letter {
  //       text-transform: capitalize;
  //     }
  //   }
  //   .currentWeatherIconCover {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //   }
  // }
}
