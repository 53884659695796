
@import '../../sass/variables';

.app {
  // height: 100%;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  
  width: 100%;
  height: 100vh;
  // min-height: 100%;
  background-color: var(--main-background);

  z-index: 1;
  // height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}
